.container {
  position: relative;
}

.hidden-div {
  display: none;
  position: absolute;
  bottom: -8px;
  left: 44px;
  background-color: white;
  padding: 10px;
  z-index: 900;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 2px;
}
.info-icon {
  height: 20px;
}

.info-icon:hover + .hidden-div {
  display: block;
}
