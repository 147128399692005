.custom-accordion-container {
  background-color: '#fafafa';
  align-items: center;
  border-radius: 16px;
  width: 98.4%;
  margin-right: 0%;
  margin: 0 auto;
  padding-bottom: 3%;
  overflow-x: auto;
  box-sizing: border-box;
  /* min-width: 510px; */
  margin: 0px 24px 0px 0px;
}

div.accordion-item {
  background-color: #fff;
}
