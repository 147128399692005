.ar-lap-book-loan {
  margin-right: 5px;

  .input-container,
  .input-container-error {
    width: 100%;
    max-width: 100%;
    height: 56px;
  }

  .input-container {
    .custom-input {
      font-size: 15px;
    }
  }

  .input-container.disabled.hasValue {
    justify-content: center;
  }

  .dropdown-options-div.selected {
    color: black;
  }

  input[disabled]#no_of_years_per_addr {
    width: 100%;
    max-width: 100%;
  }

  .entity-addition-document-title {
    margin-top: 2rem;
  }

  .entity-details-container-style {
    #reg_no_of_years {
      max-width: 100%;
    }
  }

  .input-container-error {
    input,
    .custom-input {
      padding: 20px 5px;
      margin-top: 0;
    }

    .hasValue + #age + div {
      margin-top: 15px;
    }

    .hasValue + #age,
    .hasValue + #tenure_in_months {
      padding: 0 5px;
    }

    .hasValue + #cb_age + div {
      margin-top: 15px;
    }

    .hasValue + #cb_age {
      padding: 0 5px;
    }

    .input-heading.hasValue {
      .custom-input {
        padding: 0 5px;
      }
    }

    input.custom-input.focused,
    .input-heading.focused {
      padding: 0 5px;
    }

    .error-message,
    .error-message.focused {
      margin-top: 10px;
    }
  }

  .datepicker-container {
    div:nth-child(3) {
      margin-left: 10px;
      margin-top: 5px;
    }

    label {
      color: #161719 !important;
    }
  }

  .ar-applicant-section-selfie-file-upload,
  .ar-applicant-section-pan-file-upload,
  .ar-applicant-section-aadhar-file-upload,
  .ar-coBorrower-section-selfie-file-upload,
  .ar-coBorrower-section-pan-file-upload,
  .ar-coBorrower-section-aadhar-file-upload,
  .ar-fd-section-pan-file-upload,
  .ar-propertydetails-fileupload {
    .headingStyle + .parentContainer {
      display: block;
    }
  }

  .entity-details-container-style,
  .financial-documents-container-style {
    div {
      .headingStyle + .parentContainer {
        display: block;

        .checkBoxParent {
          .input-container {
            height: 56px;
          }
        }
      }
    }

    div {
      .headingStyle {
        margin: 0;
        top: 0;
      }
    }

    .buttonstyle {
      margin-top: 14px !important;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -2px;
      }
    }
  }

  .ar-cb-entity-shareholder,
  .ar-cb-property-owner {
    margin-left: 2%;

    &-paragraph {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 2px;
      font-family: Montserrat-Regular;
      font-weight: 600;
      color: black;
    }

    &-value {
      input {
        margin-right: 3%;
      }

      label {
        margin-right: 10%;
        font-family: Montserrat-Regular;
        font-weight: 600;
        color: black;
      }
    }

    input[type='radio'] {
      cursor: pointer;
    }
  }

  .buttonstyle {
    button {
      background-color: transparent !important;
    }
  }

  .additionalDocuments-container-style {
    legend {
      display: none;
    }
  }
}
