.service-fee-recon-container-style {
  margin: 1.5rem 1.5rem 0rem 1.5rem;
}

.service-fee-recon-header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-assigned-style {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 22px;
  color: var(--Utility-Success-success-50, #008042);
  text-align: center;
  font-family: Montserrat-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 18px */
  border-radius: 4px;
  background: var(--Utility-Success-success-0, rgba(230, 255, 243, 0.7));
}

.status-not-assigned-style {
  display: flex;
  padding: 2px 8px;
  width: max-content;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Utility-Warning-warning-0, #fff5e6);
  color: var(--Utility-Warning-warning-40, #b86e00);
  text-align: center;
  font-family: Montserrat-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 18px */
}

.status-not-applicable-style {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #edeef2;
  width: max-content;
  height: 22px;
  color: var(--Neutral-neutral-80, #3e414c);
  text-align: center;
  font-family: Montserrat-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 18px */
}

.action-icon-container-style {
  display: flex;
  gap: 10px;
}

.action-icon-style {
  padding: 10px;
  align-items: flex-start;
  cursor: pointer;
}

.tool-tip-container-style {
  position: relative;
  display: inline-block;
}

.tool-tip-style {
  display: inline-flex;
  padding: 6px 10px;
  align-items: flex-start;
  gap: 10px;
  width: max-content;
  border-radius: 4px;
  border: 1px solid var(--Neutrals-D9, #d9d9d9);
  background: var(--Neutrals-F9, #f9f9f9);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 5;
  top: -30px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
}

.tool-tip-container-style:hover .tool-tip-style {
  visibility: visible;
  opacity: 1;
}

.partner-column-data-style {
  color: #141519;
  font-family: Montserrat-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 21px */
}

.product-column-data-style {
  color: #141519;
  font-family: Montserrat-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 21px */
}

.table-header-style {
  color: #625e5e;
  font-family: Montserrat-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 21px */
}

.delete-popup-text-style {
  font-size: 18px;
  margin-top: 10px;
  font-family: MontSerrat-Medium;
  color: #a4a4a4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 10%;
  padding-left: 5%;
  text-align: center;
}

.filter-icon {
  display: flex;
  gap: 2px;
  flex-direction: row;
}
