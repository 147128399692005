form .MyfilterDiv {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-top: 20px;
  position: relative;
  right: 50px;
  top: 100px;
  background-color: white;
}

.txtDpd {
  text-align: start;
  margin: 32px 8px 0px 0px;
}

.DivHeader {
  display: flex;
  justify-content: space-between;
  padding: 0px 24px !important;
  align-items: center;
}

.inputCheckBox {
  height: 24px;
  width: 28px;
}

.inputDiv {
  padding: 20px 10px 12px 0px;
  display: flex;
  align-items: center;
}

.inputDivParent {
  display: flex;
  flex-wrap: wrap;
  /* // width:    90%   ; */
}

.inputRangeStyle {
  width: 100%;
  background: #475bd8;
}

.headerBottom {
  display: flex;
  /* justify-content: space-around; */
}

.selectStyle {
  padding: 8px 64px;
  border-radius: 4px;
  margin-top: 10px;
}

.selectStyle2 {
  padding: 8px 40px;
  border-radius: 4px;
}

.lableFont {
  font-size: 12px;
  margin: 0px;
  margin-left: 2px;
}

.rangeLable {
  text-align: start;
  margin: 0px;
}

.buttonDiv {
  display: flex;
  margin-top: 150px;
  width: 100%;
  justify-content: end;
}

.buttonClear {
  background: transparent;
}

.buttonClearStyle {
  border: none;
  background: transparent;
  color: #475bd8;
  margin-right: 20px;
  cursor: pointer;
}

.buttonFilter {
  border: none;
  background: #475bd8;
  height: 36px;
  width: 96;
  border-radius: 18px;
  color: white;
  cursor: pointer;
  padding: 0px 10px;
}

.buttonCancel {
  background: transparent;
  border: none;
  cursor: pointer;
}

.lableDivFos {
  display: flex;
  justify-content: space-between;
  /* padding-top: 10px; */
}

.MyradioGroup {
  display: flex;
  flex-wrap: wrap;
}

.absoluteContainer {
  position: absolute;
  z-index: 5000;
  right: 0px;
  background: white;
}

.customStyleH4 {
  font-size: 16px;
  margin: 0px;
  font-family: Montserrat-Bold;
}

.headerBottomChild {
  width: 100%;
  margin: 0px 24px;
}

.rightContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 600px;
}

.montserratRegular {
  margin: 0px;
  padding: 0px;
  font-family: Montserrat-Regular;
}

.buttonParent {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 32px;
  font-family: Montserrat-Regular;
}

.dashboard-table-row-header {
  color: #625e5e;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard-table-row-data {
  color: #1c1c1c;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 18px;
}

.dashboard-table-row-data-link {
  color: #064586;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard-table-row-data-tooltip-text {
  visibility: hidden;
  background-color: #152067;
  color: #fff;
  font-family: Montserrat-Regular;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin-top: -40px;
  margin-left: -20px;
  position: absolute;
  border-style: solid;
  border-color: #152067 transparent transparent transparent;
  z-index: 1;
}

.dashboard-table-row-data:hover .dashboard-table-row-data-tooltip-text {
  visibility: visible;
}

.dashboard-table-row-data-link:hover .dashboard-table-row-data-tooltip-text {
  visibility: visible;
}

.collection-case-heading {
  color: var(--neutral-neutral-100, #141519);
  font-family: Montserrat-Regular;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 1.5%;
}

.tableParentDashboard .table-container .content-row-last:last-child {
  border-bottom-left-radius: 36px !important;
  border-bottom-right-radius: 36px !important;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
}

.tableParentDashboard .table-container .content-row {
  border-right: 0;
  border-left: 0;
}

.tableParentDashboard .table-container .header {
  border-right: 0;
  border-left: 0;
  /* width : auto; */
}

.myCustomClass {
  width: 199px;
  height: 46px;
  font-family: Montserrat-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 23px;
  /* Keep one definition */
  color: #1a1a1a;
  white-space: nowrap;
  border: 1px solid #dcdee6;
  background-color: #fff;
  box-shadow: none;
  margin-right: 115px;
  margin-top: 125px;
}

.buttonDiv {
  margin-right: 115px;
  margin-top: 125px;
}

.tableParentDashboard .first-element-content-style {
  width: 184px;
}

.tableParentDashboard .dashboard-table-row-data-last-item {
  width: 80%;
}

@media only screen and (max-width: 1300px) {
  .dashboard-table-row-header {
    font-size: 12px;
  }

  .dashboard-table-row-data {
    font-size: 12px;
  }
}

@media screen and (min-width: 1225px) and (min-height: 610px) {
  .tableParentDashboard .dashboard-table-row-data-last-item {
    width: 80%;
    text-align: center;
  }
}

@media only screen and (min-width: 1100px) {
  .tableParentDashboard .table-container .header .first-row-title {
    width: 160px;
  }

  .dashboard-table-row-data {
    padding: 4px 12px;
  }

  .mainContailer {
    margin-left: 30px;
  }

  .myCustomClass {
    margin-right: 20px;
  }

  .buttonDiv {
    margin-right: 15px;
    margin-top: 125px;
  }
}
