.es_container {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  padding: 2rem;
}

.es_card {
  width: 25rem;
  border-radius: 8px;
  border: 1px solid var(--neutrals-neutral-30, #cccdd3);
  background: #fff;
  padding: 1rem;
  font-family: 'Montserrat-Regular';
}

.es_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 1.6rem;
}

.es_card-hr {
  margin-top: 0;
  margin-bottom: 1rem;
}

.es_card_body-header {
  color: var(--neutrals-neutral-60, #767888);
}

.es_card_body-viewIcon {
  cursor: pointer;
}

.es_lead_card_body-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1 0 0;
}

.es_card_body-icon {
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
}

.es_card_body-each-icon {
  width: 3rem;
  filter: brightness(0.5);
}
