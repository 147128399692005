.modal-container_4 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  overflow-y: hidden;
}

.modal-container_4.open {
  opacity: 0.5;
  visibility: visible;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 3;
  overflow-y: hidden;
}

.modal_4 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: 543px;

  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10p rgba(0, 0, 0, 0.5);
  z-index: 4;
  overflow-y: hidden;
}

.icon_box_4 {
  width: 100%;
  height: 100%;
}
