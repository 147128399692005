.nach-container {
  /* background-color: red; */
  margin: 1rem 0 0 0;
}

.natch-submit-button-container {
  display: flex;
  flex-direction: column;
  float: right;
  margin-right: 2.5rem;
  margin-bottom: 1.5rem;
  height: 40rem;
  align-content: flex-end;
  justify-content: flex-end;
}
