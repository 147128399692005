.entity-details-container-style {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin-left: 1.7%;
  justify-content: space-around;
}

.entity-details-header-style {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-semibold;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 1.5rem;
}

.entity-details-sub-header-spacing-style {
  margin-top: 2.75rem;
  margin-bottom: 1rem;
}

.entity-details-checkbox-style {
  margin-left: 1rem;
  margin-top: 2.1rem;
  width: 1rem;
  aspect-ratio: 1/1;
}

.entity-details-checkbox-label-style {
  font-family: Montserrat-Regular;
  font-size: 0.9vw;
  margin-left: 0.5rem;
  color: #767888;
  margin-top: 2.1rem;
}

.entity-details-additional-kyc-doc-heading-style {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-Regular;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 5rem;
}
