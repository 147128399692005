/* styles.css */

/* Styles for the main checkbox container */
.checkBoxStyle {
  display: flex;
  margin-top: 20px;
}

/* Styles for individual input checkboxes */
.inputCheckBoxStyle {
  height: 20px;
  width: 20px;
}

/* Styles for the parent button */
.parentButtonStyle {
  position: fixed;
  top: 90%;
  width: 66%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

/* Common styles for buttons */
.buttonStyle {
  padding: 10px 24px;
}

/* Styles for the main components */
.ITRComponentStyle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Styles for headings */
.headingStyle {
  margin-top: 40px;
  margin-bottom: 20px;
}

/* Styles for paragraphs */
.paraUpload {
  margin-top: 10px;
  color: grey;
}

.buttonSave {
  padding: 10px 20px;
  border-radius: 40px;
  cursor: pointer;
  outline: none;
  border: 1px solid #134cde;
  margin-right: 10px;
  color: #134cde;
  width: 156px;
}

.buttonNext {
  padding: 10px 20px;
  border-radius: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #134cde;
  color: white;
  width: 156px;
}
