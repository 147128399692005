.user-container {
  margin: 1.5rem;
  /* background-color: red; */
}

.user-header-title {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-Semibold;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 24px;
}

.user-header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.table-header-style {
  color: #625e5e;
  font-family: Montserrat-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 21px */
}

.column-data-style {
  color: #141519;
  font-family: Montserrat-Medium;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  word-wrap: break-word;
}

.action-icon-style {
  padding: 10px;
  align-items: flex-start;
  cursor: pointer;
  filter: invert(33%) sepia(94%) saturate(1094%) hue-rotate(186deg) brightness(92%) contrast(90%);
}

.add-user-container-style {
  margin: 1.5rem;
}

.add-user-header-style {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-Semibold;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 24px;
}

.submit-button-container-style {
  display: flex;
  flex-direction: row;
  margin: 3.5rem 0 2rem;
}
