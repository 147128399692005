.accordion {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 1.5rem 1.5%;
  padding: 0 0.5rem;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  height: 3.9rem;
}

.accordion-title {
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.875rem;
  color: #1c1c1c;
  font-family: Montserrat-Regular;
}

.accordion-content {
  padding: 0 0.6rem;
  margin-bottom: 1rem;
}
