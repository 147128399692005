.applicant-details-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin-left: 1.7%;
  justify-content: space-around;
}
.applicant-details-header-style {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-semibold;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 24px;
}
.applicant-details-header-gap {
  margin-top: 40px;
}

.applicant-details-sub-header-style {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-semibold;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 24px;
  margin-top: 30px;
}

.applicant-details-sub-header-gap {
  margin-bottom: 1rem;
}

.applicant-details-checkbox-style {
  margin-left: 1rem;
  margin-top: 1rem;
  width: 1rem;
  aspect-ratio: 1/1;
}
.applicant-details-checkbox-helper-text-style {
  font-family: Montserrat-Regular;
  font-size: 0.9vw;
  margin-left: 0.5rem;
  color: #767888;
  margin-top: 1rem;
}

.applicant-details-kyc-document-container {
  display: flex;
  flex-direction: row;
  column-gap: 2%;
  margin-top: 2.125rem;
}

.applicant-details-kyc-header-style {
  color: var(--neutrals-neutral-60, #767888);
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 500;
}

.applicant-details-radio-button-label-style {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-Regular;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: 0.3rem;
}
.applicant-details-radio-button-style {
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
  height: 1.25rem;
  aspect-ratio: 1/1;
  vertical-align: middle;
}
