.user-form-input-container {
  margin-bottom: 3vh;
}

.inputBox {
  height: 55px;
  width: 45vw;
  max-width: 100vw;
  padding-top: 5px;
  background-color: transparent;
}

.footer-container {
  bottom: 3vh;
  position: absolute;
}

.user-form-button-container {
  align-self: right;
  align-content: right;
  padding: 2px;
  text-align: end;
  display: flex;
  justify-content: space-between;
  margin-right: 0.5vw;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 900px) {
  .user-form-button-container {
    margin-left: 27vw;
    margin-right: 0vw;
  }
}

@media only screen and (max-width: 600px) {
  .user-form-button-container {
    margin-left: 42vw;
    margin-right: 0vw;
  }
}
