#autoCompleteInput {
  /* width: 100%; */
  padding: 8px 10px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  color: #989898;
  outline: none;
  border: none;
  font-family: Montserrat-regular;
  font-size: 15px;
}

ul.suggestions {
  position: absolute;
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
}

ul.suggestions li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  /* border-bottom: 1px solid #ccc; */
  color: #989898;
}

ul.suggestions li:hover {
  background-color: #f2f2f2;
}

.inputAutocomplete {
  padding: 8px 10px;
  width: 260px;
  border-radius: 4px;
}
