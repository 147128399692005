.overview-card-container-style {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #ececec;
  background-color: #fff;
  padding: 0% 3% 1% 12px;
}

.overview-card-title-container {
  margin-top: 12%;
  margin-bottom: 3%;
}

.overview-card-title {
  color: #6d6d6d;
  font-family: Montserrat-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: -0.25px;
  white-space: nowrap;
}

.overview-card-amount {
  color: #404040;
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;
  letter-spacing: -0.25px;
}

@media only screen and (max-width: 1420px) {
  .overview-card-title {
    font-size: 1.2vw;
  }

  .overview-card-amount {
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 1300px) {
  .overview-card-title {
    font-size: 1.1vw;
  }

  .overview-card-amount {
    font-size: 1.3vw;
  }
}
