.summary-buttons {
  display: flex;
  gap: 15px;
  margin-top: 3%;
}
.overview-container {
  display: grid;
  margin: 2%;
  justify-content: space-between;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
}
.product-container {
  display: grid;
  grid-column-gap: 2%;
  grid-template-columns: 49% 49%;
  margin: 0 2%;
}
.recon-summary-heading {
  margin-left: 1%;
  margin-top: 4%;
  margin-bottom: 0.5rem;
  font-size: 20px;
  font-family: Montserrat-Medium;
  font-weight: 600;
  line-height: 1.5;
  color: #141519;
  white-space: nowrap;
}
.partner-recon-summary-heading {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
