.table-alignment-css {
  .content-style,
  .title {
    width: auto;
  }
  .header {
    height: auto;
  }
  .content-style,
  .content-row-last {
    height: auto;
    overflow: auto;
  }
  .content-row {
    height: auto;
  }
  .first-element-content-style {
    width: auto;
  }
  .first-row-title {
    width: auto;
  }
  .content-row-hovered {
    height: auto;
  }
}
.table-alignment-css.disb-table {
  .first-element-content-style {
    word-break: break-all;
    width: auto;
  }
}
.disbursement-popup-error {
  .input-container-error,
  .error-message {
    font-size: 9px !important;
  }
}
