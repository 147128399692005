.coBorrower-container-style {
  display: flex;
  flex-direction: column;
  width: 98%;
  justify-content: space-around;
}

.coBorrower-details-heading-container-style {
  display: flex;
  position: sticky;
  top: 0;
  padding-top: 1rem;
  margin-bottom: 1rem;
  background: #fff;
  z-index: 5;
  justify-content: space-between;
}
.coBorrower-details-heading-style {
  font-family: Montserrat-Bold;
  margin-left: 2%;
  color: #161719;
  font-size: 1.3vw;
  margin-bottom: 1.25rem;
}
.coBorrower-details-checkBox-style {
  margin-left: 1rem;
  margin-top: 2rem;
  width: 1rem;
  aspect-ratio: 1/1;
}

.coBorrower-container-checkBox-label-style {
  font-family: Montserrat-Regular;
  font-size: 0.9vw;
  margin-top: 2rem;
  margin-left: 0.5%;
  color: #767888;
}
