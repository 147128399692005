.offerImage {
  margin-top: 0;
  margin-left: 0;
  margin-right: 2vw;
  margin-bottom: 0;
  width: auto;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.textStyleHead {
  padding: 0% 2% 2% 0%;
  text-align: left;
  color: #767888;
  margin-bottom: 0.3%;
}

.offerHead {
  font-size: 27px;
  font-style: 700;
  line-height: 175%;
  font-family: 'Montserrat-bold';
  padding-bottom: 0.25%;
  padding-top: 3%;
  padding-left: 6px;
  color: black;
  margin-bottom: 1%;
}

.containerStyle {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border: 1px solid #cccdd3;
  border-radius: 10px;
  margin: 0% 3% 0% 0%;
}
.confirmation-popup-text-style {
  font-size: 18px;
  margin-top: 12px;
  font-family: MontSerrat-Medium;
  color: #a4a4a4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 10%;
  padding-left: 5%;
  margin-right: 1%;
  text-align: initial;
}

.title-text {
  font-family: MontSerrat-Regular;
  font-size: 12px;
  line-height: 18px;
  color: #6b6f80;
  padding-top: 10px;
}

.body-text {
  font-family: MontSerrat-Medium;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  color: #141519;
}
