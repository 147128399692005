.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: var(--neutrals-neutral-0, #f1f1f3);
  border-radius: 4px;
  color: var(--neutral-80, #4b4d57);
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  text-align: center;
  font-family: Montserrat-Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  height: fit-content;
  width: fit-content;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  border: none;
  color: #161719;
  cursor: pointer;
  display: flex;
  font-size: 25px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.ir-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.ir-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
}

.ir-close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}

.ir-close-button:hover {
  background-color: darkgray;
}

.ir-show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.ir-wrapper {
  overflow: auto;
  border-radius: 1rem;
  border: 1px solid #ededed;
  overflow-y: auto;
  max-height: 32rem;
}

.ir-model-table {
  border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  max-width: 100%;
}

.ir-model-table-head {
  border-bottom: 1px solid #dcdee6;
  background: #f9f8fa;
  height: 3rem;
}

.ir-model-table-body {
  border-bottom: 1px solid #dcdee6;
  background: #fff;
  height: 3rem;
}

.ir-status-table td {
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.ir-model-table-head th,
.ir-model-table-body td {
  padding-left: 0.5rem;
}

.coa-report-table-head {
  margin-bottom: 3.75rem;
}

.coa-report-table-body {
  font-family: Montserrat-SemiBold;
  color: #161719;
  font-size: 130%;
  margin-left: 0.8%;
  margin-bottom: 1%;
}
