.create-business-modal-container-style {
  margin: 1.5% 1.5% 1.5% 1.5%;
}

.create-business-modal-header {
  color: #1c1c1c;
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1.5rem;
}

.fees-heading-style {
  color: var(--Neutral-neutral-100, #141519);
  /* Heading/H7/Semibold */
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
  /* 21px */
}
.input-label-style {
  margin: 0 1rem 0 0.5rem;
  color: var(--Neutral-neutral-100, #141519);
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 150%; /* 21px */
}
.input-checkbox-style {
  width: 16px;
  aspect-ratio: 1/1;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
}
