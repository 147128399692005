/* styles.css */

.LableDiv {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.clearButton {
  background: transparent;
  color: #475bd8;
  border: none;
}

.applyFilterStyle {
  background: #475bd8;
  margin-left: 10px;
  border: none;
  color: white;
  border-radius: 40px;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;
}

.formComponent {
  margin: 0 40px;
}

.filterDiv {
  padding: 22px;
}

.formChild {
  position: absolute;
  z-index: 5000;
  right: 0px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  margin-right: 30px;
}

.filterChild {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.h4Style {
  font-size: 16px;
  font-family: Montserrat-Bold;
}

.buttonCancel {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.headerBottom {
  display: flex;
}

.rangeDiv.rangeLable {
  font-family: Montserrat-Bold;
}

.right {
  display: grid;
  grid-template-columns: auto auto auto;
}

.pincodeInput {
  height: 42px;
  width: 180px;
  margin-right: 10px;
  padding: 8px;
  color: #141519;
  font-family: Montserrat-Regular;
  border-radius: 4px;
  outline: none;
  border: 1px solid #9e9e9e;
  font-size: 18px;
  color: #989898;
}
.buttonDivParent {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 180px;
  font-family: Montserrat-Regular;
}

.buttonDivChild {
  display: flex;
  justify-content: flex-end;
}

.para {
  margin: 0px;
  padding: 0px;
}

div.gridItem {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  display: flex;
  margin: 0px 30px 16px 0px;

  align-items: center;
  font-family: Montserrat-Regular;
}

.inputCheckBox {
  text-align: 'center !important';
  height: 40px;
  width: 20px;
}

.paraInput {
  text-align: 'center';
  margin: 0px 0px 0px 8px;
  font-weight: 500;
}

.txtDpd {
  font-family: 'Montserrat-Bold';
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}
