div.multiselect-table {
  border-radius: 1rem;
  border-top: 1px solid #e5e5e8;
}

.multiselect-table .MuiDataGrid-columnsContainer {
  height: 4rem;
  border-radius: 1rem 1rem 0rem 0rem;
  background: #e5e5e8;
}

.multiselect-table div.MuiDataGrid-colCellTitle {
  color: #161719;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.multiselect-table .MuiDataGrid-row {
  line-height: 150%;
}

.multiselect-table div.MuiDataGrid-cell {
  height: 4rem;
  color: #161719;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
}

.multiselect-table .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root {
  color: #c5c5c5;
}
