@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
div.lap-upload-and-view-file-input-wrapper {
  .spanParent {
    //   display: flex;
    //   justify-content: space-between;
    display: flex;
    justify-items: end;
    align-items: center;
    background: #fff;
    padding: 0px 12px; // 14px;
    border: 1px solid #bbbfcc;
    width: 100%;
    max-width: 100%; // 320px;
    border-radius: 8px;
    height: 56px;
    &.greenBorder {
      border: 1px solid green;
    }
  }
  .inputContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 5rem);
  }
  .fileLabel {
    font-size: 12px;
    color: #525252;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .fileName {
    font-size: 16px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .inputBox.uploadAndViewFileInput {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: 100%;
    // padding: 12px;
  }
  .uploadAndViewFileInput {
    display: none;
  }
  input[type='file'].uploadAndViewFileInput {
    display: none;
  }
  input[type='file'].uploadAndViewFileInput::file-selector-button {
    // background-color: transparent;
    // border: unset;
    display: none;
  }
  .actionBtn {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: auto;
    // padding: 0px 12px;
    button.lableStyle {
      border: unset;
      background-color: transparent;
      padding: 0px;
    }
  }

  .ITRStyle {
    margin-bottom: 10px;
  }

  .paraStyle {
    color: #767888;
    font-family: Montserrat-Regular;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 0px;
  }

  .parentContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 40px;
  }

  .checkBoxParent {
    /* Add any styles you need for checkBoxParent */
  }

  .headingStyle {
    margin-top: 20px;
    position: relative;
    top: 10px;
    font-weight: 600;
    color: #32325d;
    margin-bottom: 10px;
  }

  .spanStyle {
    font-size: 16px;
    font-family: Montserrat;
    background: #ffffff;
  }

  .lableStyle {
    color: #134cde;
    cursor: pointer;
    background: '#FFFF';
    margin-bottom: 0px;
  }

  .paraUpload {
    font-size: 16px;
  }
  .errorParentButtonStyle {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-top: 2%;
  }
}
