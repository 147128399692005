/* Alert.css */
.parentDiv {
  margin: 20px;
  max-width: 35vw;
  min-width: 30vw;
  border-radius: 8px;
  justify-content: space-between;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  animation: alert-animation 0.5s ease-in-out;
  padding: 14px;
}

@keyframes alert-animation {
  0% {
    right: -10%;
  }

  100% {
    right: -0%;
  }
}

@media only screen and (max-width: 1100px) {
  .parentDiv {
    margin: 20px;
    max-width: 55vw;
  }
}
