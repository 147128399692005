div.accordion-content-edit {
    div.input-container {
        width: 98%;
    }
}
div.viewMode {
    div.accordion-content-edit {
        div.input-container {
            &.disabled {
                background-color: #fff !important;
                .custom-input  {
                    background-color: #fff !important;
                }
            }
        }
    }
}

