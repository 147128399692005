.main-container {
  display: flex;
  height: 100vh;
}

.left-side-container {
  background: linear-gradient(202deg, rgb(152 211 254), rgb(191, 220, 254), rgb(161, 215, 255), rgb(193 172 221));
  backgroundrepeat: no-repeat;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Bold;
}

.inside-container {
  color: #fff;
  padding: 2vw;
  border-radius: 1vw;
  /* border: 2px solid #fff; */
  background: rgba(255, 255, 255, 0.4);
  width: 70%;
}
.logo {
  margin: 2vw 0vw;
}
.loanheading {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  line-height: 125%;
}
.managementheading {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  line-height: 125%;
}
.systemheading {
  color: black;
  font-size: 60px;
  font-weight: 700;
  line-height: 125%;
}
.paragraphheading {
  color: #868686;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 60px;
  line-height: 140%;
  font-family: Montserrat-SemiBold;
  /* word-spacing: 0.2vw; */
}
.right-side-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5vw;
}
.inside-right-heading-container {
  margin-left: 15%;
  margin-right: 15%;
}
.login-heading {
  font-size: 2vw;
  color: black;
  line-height: 150%;
  font-weight: 600;
  font-family: Montserrat-SemiBold;
}
.login-subheading {
  font-size: 1.125vw;
  color: #6e6e6e;
  margin-top: -0.5vw;
  line-height: 150%;
  font-weight: 500;
  word-spacing: 0.2vw;
}
.inputdiv {
  margin-top: 2.5vw;
}
.errormessage {
  color: red;
  margin-top: 1.5vw;
  text-align: center;
  font-weight: bold;
}
.buttondiv {
  text-align: center;
  margin-top: 2.5vw;
  text-transform: none;
}
