.assign-cases-radio-button-input {
  margin-right: 15px;
}

.assign-cases-radio-button-label {
  margin-top: 15px;
  color: #1c1c1c;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  align-items: center;
}

.assign-cases-submitButton-container {
  display: flex;
  justify-content: flex-end;
  width: 37.5vw;
  position: absolute;
  bottom: 3vh;
  margin-right: 2vw;
}
