.lap-accordion-section .custom-accordion-container .accordion-item .accordion-content .single-container {
  /* below 1.725 rem value is addition of m-l and m-r. so make sure to adjust it when updating margins */
  width: calc(25% - 1.725rem);
  margin-left: 0.6rem;
  margin-right: 1.125rem;
  margin-bottom: 1.125rem;
  position: relative;
}
div.accordion-item-edit,
div.accordion-section {
  background-color: #fff;
}

.lap-accordion-section .custom-accordion-container .accordion-item .accordion-content .single-container .body-accordion {
  display: flex;
}

.lap-accordion-section .title-name-head {
  font-family: 'Montserrat-SemiBold';
}

.lap-accordian-field-value p {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat-Regular';
  color: '#151515';
}

.lap-accordion-section .rejected-alert-container {
  padding: 0px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  border-radius: 8px;
  margin-right: 20px;
  background-color: #ffecec;
  border: 2px solid #c00;
}

.lap-accordion-section .cross-icon {
  margin: 17px 0px 0px 16px;
  width: 20px;
  height: 20px;
}

.lap-accordion-section .rejected-alert-container-title {
  display: flex;
  color: #c00;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  padding: 16px 17.5px 0px;
  font-family: 'Montserrat-SemiBold';
}

.lap-accordion-section .rejected-alert-container-msg {
  display: flex;
  padding: 0px 17.5px;
  margin-top: 4px;
  word-break: break-word;
  font-size: 14px;
  font-weight: 500;
  color: var(--neutral-100, #161719);
  font-family: 'Montserrat-Medium';
}

.dropdownWrapper .error-message {
  margin-top: 14px;
}
div.shareholdingRow {
  .error-message.focused {
    margin-top: 28px;
  }
}
.error-message {
  line-height: 1.5;
}

.lap-accordion-section .custom-accordion-container .accordion-item .title-name-head {
  font-family: 'Montserrat-Bold';
}

.accordion-item .buttonNext {
  padding: 12px 24px;
  border-radius: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  gap: 10px;
  background: linear-gradient(180deg, #134cde 0%, #163fb7 100%, #163fb7 100%);
  color: white;
  width: max-content;
}

.accordion-item .buttonNext:disabled {
  background-color: rgb(204, 205, 211) !important;
  color: white;
  opacity: 0.65;
  box-shadow: none;
  background: none;
}
div.datepicker-container {
  width: 100%;
}
h2.headingStyle {
  display: none;
}
div.single-container:has(div[name='subHeading']) {
  flex-basis: 100%;
  max-width: none !important;
}
div.single-container:has(div[name='full_line_msg']) {
  flex-basis: 100%;
  max-width: none !important;
  justify-content: center !important;
}
div.single-container:has(div[name='title_document_row']) {
  width: 100% !important;
}
div.single-container:has(div[name='accordion_divider']) {
  width: 100% !important;
  margin: 0 !important;
  div.body-accordion {
    margin: 0 !important;
  }
}
