.ar-loan-data-upload-status {
  margin-top: 5px;

  &-search-box {
    .password-toggle {
      top: 25%;
    }
  }

  &-action {
    &-dashboard {
      display: flex;

      button:nth-child(1) {
        padding: 0;
        font-size: 12px;
        background-color: white;
        color: blue;
        font-weight: 800;
        border: none;
        margin-right: 5px;
        &:hover {
          color: black;
        }
      }

      button:nth-last-child(1) {
        padding: 0;
        font-size: 12px;
        background-color: white;
        color: blue;
        font-weight: 800;
        border: none;
        margin-left: 5px;
        &:hover {
          color: black;
        }
      }
    }
  }

  &-dashboard {
    margin: 2%;

    .table-container:nth-last-child(2) {
      overflow-x: auto;
    }

    .header {
      border-right: none;
      padding: 8px 2px 8px 8px;

      div {
        width: 100%;
        justify-content: center;
        margin: 1px;
      }

      :nth-last-child(1) {
        position: sticky;
        right: 0;
        border-color: #c4bfbf;
        background: #e5e5e8;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: 5em;
        width: 100%;
        display: grid;
        place-items: center;
        place-content: center;
      }
    }

    .content-row-last,
    .content-row-hovered,
    .content-row,
    .clicked-row {
      border-right: 1px solid #e5e5e8;
      padding: 8px 2px 8px 8px;

      div:not(.styleParentDiv) {
        width: 100%;
        justify-content: center;
        margin: 1px;
        word-break: break-all;
      }

      .content-style:nth-last-child(1) {
        position: sticky;
        right: 0;
        border-color: #ededed;
        height: 5em;
        width: 100%;
        display: grid;
        place-items: center;
        place-content: center;
        background-color: white;
      }

      .content-style:nth-last-child(2) {
        font-size: 10px;
        font-weight: 800;
      }

      :nth-child(4) {
        display: flex;
        flex-direction: column;
        line-height: 10px;
        width: 100%;
        word-break: unset;

        .ar-loan-data-upload-progress-bar {
          .MuiLinearProgress-root {
            background-color: #e5e5e8;
            border-radius: 10px;
          }

          &-count {
            display: flex;
            width: 100%;
            justify-content: space-between;
            font-size: 8px;
            font-weight: 800;

            :nth-child(2) {
              text-align: right;
            }
          }

          &-success {
            .MuiLinearProgress-bar {
              background-color: #069952;
            }
          }

          &-pending {
            .MuiLinearProgress-bar {
              background-color: #eda12f;
            }
          }

          &-failure {
            .MuiLinearProgress-bar {
              background-color: #ff0000;
            }
          }

          &-status {
            p {
              margin-bottom: 0;
              line-height: 0;
              font-weight: 800;
              font-size: 8px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
