.product-card-container-style {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #ececec;
  background-color: #fff;
  padding: 4% 4% 0% 24px;
  margin-bottom: 2rem;
}

.product-card-title-container {
  margin-top: 1%;
  margin-bottom: 5%;
}

.product-card-title {
  color: #141519;
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  letter-spacing: -0.25px;
  white-space: nowrap;
}

.product-card-key-value {
  display: inline-block;
  margin-right: 20px;
}

.product-card-key {
  color: #6d6d6d;
  font-family: Montserrat-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: -0.25px;
  white-space: nowrap;
}

.product-card-row {
  display: grid;
  grid-template-columns: 34% 33% 33%;
  margin-bottom: 5%;
}

.product-card-value {
  color: #141519;
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;
  letter-spacing: -0.25px;
  white-space: nowrap;
}

@media only screen and (max-width: 1400px) {
  .product-card-title {
    font-size: 16px;
  }

  .product-card-value {
    font-size: 14px;
  }

  .product-card-key {
    font-size: 14px;
  }
}

/* @media only screen and (max-width: 1300px) {
    .product-card-title {
        font-size: 1.1vw;
    }

    .product-card-value {
        font-size: 1.3vw;
    }
} */
