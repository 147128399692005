.offerImageContainer {
  background-color: #eef6ff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.lap-offer .offerImage {
  margin: 0;
  width: auto;
  height: 100%;
  max-width: 243px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.lap-offer .flex-1 {
  display: flex;
  flex: 1;
}
.lap-offer .flex-column {
  display: flex;
  flex-direction: column;
}

.lap-offer .field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lap-offer .button-container {
  margin-top: 24px;
  margin-bottom: 24px;
}
.lap-offer .text-button {
  font-size: 16px;
  margin-right: 3%;
  padding: 1%;
  box-shadow: none;
  text-decoration: none;
}
.lap-offer .final-offer-button {
  font-size: 14px;
  margin-right: 3%;
  padding: 1%;
  box-shadow: none;
  text-decoration: none;
  padding: 8px 24px;
  border: 1px solid #008042;
  color: #008042;
  border-radius: 71px;
}
.lap-offer .final-offer-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.lap-offer .confirm-popup-button-container {
  display: flex;
  margin-top: 8%;
}
.lap-offer .cmview-button-container {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.lap-offer .offer-details-container {
  padding: 20px;
}

.textStyleHead {
  padding: 0% 2% 2% 0%;
  text-align: left;
  color: #767888;
  margin-bottom: 0.3%;
}

.offerHead {
  font-size: 27px;
  font-style: 700;
  line-height: 175%;
  font-family: 'Montserrat-bold';
  color: black;
  margin-bottom: 1%;
  padding: 0;
}

.containerStyle {
  width: 98.4%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border: 0.063rem solid #bbbfcc;
  border-radius: 10px;
  margin: 0% 3% 0% 0%;
}
.confirmation-popup-text-style {
  font-size: 18px;
  margin-top: 12px;
  font-family: MontSerrat-Medium;
  color: #a4a4a4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 10%;
  padding-left: 5%;
  margin-right: 1%;
  text-align: initial;
}

.offer-detail-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 24px;
}
