.anchor-container-style {
  margin: 1.5rem;
  /* background-color: red; */
}

.anchor-header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.table-header-style {
  color: #625e5e;
  font-family: Montserrat-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 21px */
}

.table-column-data-style {
  color: #141519;
  font-family: Montserrat-Medium;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  word-wrap: break-word;
  cursor: pointer;
  width: 100%;
}

.create-anchor-container-style {
  display: grid;
  row-gap: 1.5rem;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  width: 100%;
  margin: 1.5rem 0;
}

.add-director-icon-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.add-director-icon-style {
  width: 18px;
  cursor: pointer;
}

.delete-director-icon-style {
  width: 3rem;
  cursor: pointer;
}

.add-director-text-style {
  font-family: Montserrat-Semibold;
  font-weight: 600;
}

.submit-button-container-style {
  display: flex;
  flex-direction: row;
}

.anchor-details-container {
  margin: 1.5rem;
  display: flex;
  gap: 2%;
}

.anchor-info-item-container {
  display: flex;
  width: 100%;
  align-items: center;
  font-family: Montserrat-Medium;
  margin-bottom: 0.5rem;
}
.anchor-info-item-style {
  flex-basis: 30%;
}
.anchor-info-data-style {
  flex-basis: 70%;
  background-color: #f6f7f9;
  padding: 0.5rem;
  border: 1px solid #abaeb3;
  border-radius: 0.4rem;
}
.anchor-details-header-style {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Montserrat-Medium;
}

.document-section-container-style {
  margin: 0.5rem 0 0 1rem;
  display: grid;
  grid-column: 0;
  margin-top: 18px;
  margin-bottom: 45px;
  grid-template-columns: 33% 33% 33%;
  margin-left: 12px;
}
