.container-style {
  margin-right: 5px;
  min-height: 650px;
  border-left: 3px solid #e5e5e8;
  transition: width 0.2s ease-in;
}

.sliderContent {
  max-height: 80vh;
  overflow-y: scroll;
  scrollbar-width: 5px;
}

.sliderContent::-webkit-scrollbar {
  width: 5px;
}

.sliderContent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sliderContent::-webkit-scrollbar-thumb {
  background: #888;
}

.sliderContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.icon-style {
  width: 44px;
  height: 44px;
  background: white;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
