.create-loan-header {
  display: flex;
  flex-direction: row;
  font-family: Montserrat-Semibold;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 13px;
  color: #161719;
}

.create-loan-container {
  display: grid;
  row-gap: 30px;
  column-gap: 2%;
  grid-template-columns: 31.5% 31.5% 31.5%;
  width: 100%;
}

.create-loan-button-css {
  display: flex;
  flex-direction: row;
  float: right;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-top: 10px;
}

.create-loan-checkbox-css {
  margin-top: -5px;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #767888;
  margin-left: 16px;
}

.create-loan-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.span {
  font-family: Montserrat-Regular;
  font-size: 16px;
  margin-left: 8px;
  font-weight: 500;
  line-height: 24px;
  color: #161719;
  margin-top: 2px;
}

.span-insurance {
  font-family: Montserrat-Regular;
  font-size: 16px;
  margin-left: 30px;
  font-weight: 600;
  line-height: 24px;
  color: #134cde;
  margin-top: 2px;
  cursor: pointer;
}

.confirmation-popup-text-style {
  font-size: 18px;
  margin-top: 12px;
  font-family: MontSerrat-Medium;
  color: #a4a4a4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 10%;
  padding-left: 0%;
  margin-right: 1%;
  text-align: initial;
}

.benefit-logo {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.benefit-head {
  color: #161719;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 0.5rem;
  font-family: Montserrat-Medium;
}

.benefit-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  font-family: Montserrat-Semibold;
}

.benefit-label {
  padding: 0.625rem 0 0 0;
  color: #a4a4a4;
}

.benefit-description {
  padding: 0.625rem 0 0 0;
  color: #161719;
  font-family: Montserrat-Medium;
}

.benefit-cancel {
  display: flex;
  justify-content: right;
  margin: 1rem 0;
}

.benefit-popup-fields {
  color: #a4a4a4;
  font-family: Montserrat-Regular;
}
