.share-holding-pattern-form-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin-left: 1.7%;
  justify-content: space-around;
}

.share-holding-pattern-form-title {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-semibold;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
}

.share-holding-table-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e8;
  color: black;
  font-size: 16px;
  border-radius: 15px;
}

.share-holding-table-header {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
  width: 100%;
}
.share-holding-table-header div {
  display: flex;
  color: var(--neutrals-neutral-100, #161719);
  margin: 0 22px;
  font-family: Montserrat-SemiBold;
}

.share-holding-table-header-name {
  width: 40.5%;
  
}
.share-holding-table-header-value {
  width: 44.5%;
}
.share-holding-table-header-action {
  width: 5%;
  justify-content: center;
}

/* select class share-holding-table-header secont div */
.share-holding-table-row {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
  width: 100%;
  background-color: white;
  color: black;
  font-size: 16px;
  border-bottom: 1px solid var(--neutrals-neutral-10, #e5e5e8);
  border-left: 1px solid var(--neutrals-neutral-10, #e5e5e8);
  border-right: 1px solid var(--neutrals-neutral-10, #e5e5e8);
}

.share-holding-add-new-container p {
  font-size: 10px;
  color: black;
  margin: 0;
}

.share-holding-table-name-cell {
  margin: 0 22px;
  width: 40.5%;
}

.share-holding-table-value-cell {
  width: 44.5%;
}
.share-holding-table-action-cell {
  margin: 0 22px;
  width: 5%;
  justify-content: center;
}

.share-holding-table-total-percent-value {
  font-family: Montserrat-Regular;
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.border-curve {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.share-holding-add-new-container {
  background-color: white;
  border: 1px solid var(--neutrals-neutral-10, #e5e5e8);
  border-top: none;
  padding: 0 22px;
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: Montserrat-SemiBold;
}

.share-holding-add-new-container button {
  border: none;
  font-size: 1.1rem;
  color: #134CDE;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}
.share-holding-add-new-container button:disabled {
  color: #b3b3b3;
  cursor: not-allowed;
}

.share-holding-cerficate-upload-container > h5 {
  color: var(--neutrals-neutral-100, #161719);
  font-family: Montserrat-semibold;
  font-size: 20px;
  margin-bottom: 16px;
  margin-top: 40px;
}

.share-holding-final-submit-container {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 40px;
  flex-flow: row-reverse;
  margin-bottom: 3rem;
}
