.comment-heading-style {
  color: var(--Warning-Warning-10, #eda12f);
  font-family: Montserrat-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.info-icon-style {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.comment-subHeading-style {
  color: var(--Neutral-neutral-100, #141519);
  /* Heading/H7/Semibold */
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 21px */
  margin-top: 1%;
}

.comment-content-style {
  color: var(--Neutrals-Neutral-100, #161719);
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  margin-top: 1.5%;
}

ol {
  list-style-position: outside;
  /* Move the index inside the list item */
  padding-left: 3%;
}
