.fallback {
  display: block;
  border: 0.063rem solid #f44336;
  padding: 1rem;
  color: #f44336;
  border-radius: 0.313rem;
}
.fallback__title {
  padding: 0;
  margin: 0;
}
