.upload-grid-card {
  width: 96%;
  border-radius: 2%;
  border: 1px solid #ececec;
  background-color: #fff;
  padding: 1%;
  margin: 1.5% 0% 0% 1.5%;
}

.bulk-upload-button-containers {
  display: grid;
  grid-template-columns: auto auto;
  width: 200px;
}

.text-margin {
  margin-left: 22px;
  font-weight: 500;
}

.label-margin {
  margin-left: 8px;
}
