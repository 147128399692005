.search-button {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 4px;
  background: var(--primary-blue-button-gradient, linear-gradient(180deg, #134cde 0%, #163fb7 100%, #163fb7 100%));
}

.filter-div {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 20px;
}

.makeStyles-mainContent-1 {
  overflow: visible !important;
}
