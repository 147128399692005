.tab {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.tab.selected {
  color: rgb(43, 82, 225);
  border-bottom: 2px solid rgb(43, 82, 225);
  cursor: pointer;
}

ab {
  display: inline-block;
  padding: 10px;
  color: black;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
/* Style for the tab when hovered */
ab:hover {
  color: '#3A86FF';
  border-bottom: 2px solid rgb(42, 81, 220);
}
/* Style for the tab when active */
ab:active {
  color: '#3A86FF';
  border-bottom: 2px solid rgb(43, 82, 225);
}
